import './Card.css';

type CardProps = {
    cardHeader: string;
    cardImage: string;
    available?: boolean;
    link?: string;
};

export const Card = ( props: CardProps ) => (
    <div className="custom-card"
         key={props.cardHeader}
         style={{
            backgroundColor: props.available === undefined ? '#FFF' : props.available ? '#79c698' : '#ec93a2' }}
    >
        
        <div className="custom-card-header">
            <h3>{props.cardHeader}</h3>
        </div>
        <div className="custom-card-image">
            <img src={props.cardImage} alt="card header" />
        </div>
        {props.available ? 
        <div className="custom-card-body">
            <a href={props.link} target="_blank" rel="noreferrer" className="btn btn-primary">Register</a>
        </div> : null
        }
        
        <div className="custom-card-footer">
            {props.available === undefined ? '' : props.available ? 'Available' : 'Unavailable'}
        </div>
    </div>
  );
