import './SearchAndServices.css';
import { useState } from 'react';
import { Card } from '../Card/Card';
import { Service } from '../types/types';
import { supportedServices } from '../supportedServices';

export const SearchAndServices = () => {
  const [services, setServices] = useState<Service[]>(supportedServices);
  const [username, setUsername] = useState('');

  const handleClick = () => {
    if(username.length > 0) {
        checkAvailabilityAll(username);
    }
  }

  const checkAvailabilityAll = async (username: string) => {
    try {
      const response = await fetch(`https://crimson-glade-e492.cryck.workers.dev/?username=${username}`);
      if (!response.ok) {
        throw new Error("Error fetching data");
      }
    
      const result = await response.json();
    
      const updatedServices = services.map(service => {
        return {...service, available: result.find((r: { name: string; }) => r.name === service.name)?.available};
      });
    
      setServices(updatedServices);
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
      // Display or handle the error message here
    }
  }

  const checkDomainAvailablity = async (username: string) => {
      const response = await fetch(`https://crimson-glade-e492.cryck.workers.dev/?category=domains&username=${username}`);
      const promise = response.json();

        const result: Service[] = await promise;
        const updatedServices = services.map(service => {
            const updatedService = {...service};
            if (service.category === "domains") {
              const updatedAvailability = result.find(domain => domain.name === service.name);
              updatedService.available = updatedAvailability?.available;
            }
            return updatedService;
          });
        setServices(updatedServices);
}

  return (
    <div className='search-services'>
      <div className="input-group mb-3">
      <input 
          type="text" 
          className="form-control" 
          placeholder="Check your brand name now" 
          aria-label="brand name" 
          aria-describedby="button-addon2" 
          onChange={(e) => setUsername(e.target.value)}
          onKeyUp={(e) => {
          if (e.key === 'Enter') {
              handleClick();
          }
          }}
          required
      />
      <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={handleClick}>Search <kbd>Enter</kbd></button>
      </div>
        <div className='card-container'>
                {services.map((currentService) => (
                    <Card 
                        key={currentService.name}
                        cardHeader={`${currentService.name}`}
                        available={currentService.available} 
                        cardImage={currentService.image ?? `https://logo.clearbit.com/${currentService.name}.com`}
                        link={currentService.registerLink}
                    />
                ))}
        </div>
    </div>
  );
};