import '../App.css';
import { Header } from '../components/Header/Header';
import { Footer } from '../components/Footer/Footer';
import { SearchAndServices } from './SearchAndServices/SearchAndServices';

export const Main = () => {
    return ( 
    <div className='main-view'>
        <Header />
            <div className='hero-text'>
                Where brands start their social media journey.
            </div>
            <SearchAndServices />
        <Footer />
    </div>);
}