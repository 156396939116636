import './App.css';
import { Router } from './Router';

const App = () => (
    <div className="App">
      <Router />
    </div>
  );

export default App;
