import { Route, Routes } from 'react-router-dom';
import { Main } from './components/Main';
import MarkdownViewer from './components/MarkdownViewer/MarkdownViewer';

export const Router = () => {
  return (
    <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/privacy" element={<MarkdownViewer filePath='content/privacy.md' />} />
        <Route path="/tos" element={<MarkdownViewer filePath='content/tos.md' />} />
        <Route path="/about" element={<MarkdownViewer filePath='content/about.md' />} />
        <Route path="/contact" element={<MarkdownViewer filePath='content/contact.md' />} />
    </Routes>
  );
};