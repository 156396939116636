import './Header.css';
import styled from 'styled-components';
import { Menu } from '../Menu/Menu';

const StyledHeader = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
`;

export const Header = () => (
    <StyledHeader>
      <div className='logo'>
        <a href="/"><h1>Sociablebrand</h1></a>
      </div>
      
      <Menu />
    </StyledHeader>
  );
  
