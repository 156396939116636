import './MarkdownViewer.css';
import React from 'react';
import { marked } from 'marked';
import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';
import styled from 'styled-components';

interface Props {
  filePath: string;
}

const StyledContent = styled.div`
  margin: 0 20%;
`;

const MarkdownViewer: React.FC<Props> = ({ filePath }) => {
  const [markdown, setMarkdown] = React.useState('');

  React.useEffect(() => {
    fetch(filePath)
      .then(response => response.text())
      .then(text => setMarkdown(text))
  }, [filePath]);

  return (
    <StyledContent>
      <Header />
        {React.createElement("div", {
          dangerouslySetInnerHTML: { __html: marked.parse(markdown) }
        })}
        <Footer />
    </StyledContent>
  );
};

export default MarkdownViewer;