import { Service } from "./types/types";

export const supportedServices: Service[] = [
    {
        "name": "Spotify",
        "available": undefined,
        "url": "https://www.spotify.com",
        "registerLink": "https://www.spotify.com/us/signup",
        "category": "podcast" 
    },
    {
        "name": "Github",
        "available": undefined,
        "url": "https://github.com/",
        "registerLink": "https://github.com/join",
        "category": "development" 
    },
    {
        "name": "Twitter",
        "available": undefined,
        "url": "https://twitter.com",
        "registerLink": "https://twitter.com/i/flow/signup",
        "category": "socialmedia" 
    },
    {
        "name": "StackExchange",
        "available": undefined,
        "url": "https://stackoverflow.com",
        "registerLink": "https://stackoverflow.com/users/signup",
        "category": "development" 
    },
    {
        "name": "Tumblr",
        "available": undefined,
        "url": "https://www.tumblr.com",
        "registerLink": "https://www.tumblr.com/register",
        "category": "socialmedia" 
    },
    {
        "name": "Steampowered",
        "available": undefined,
        "url": "https://steampowered.com",
        "registerLink": "https://store.steampowered.com/join",
        "category": "gaming" 
    },
    {
        "name": "Reddit",
        "available": undefined,
        "url": "https://www.reddit.com",
        "registerLink": "https://www.reddit.com/register/",
        "category": "socialmedia" 
    },
    {
        "name": "com",
        "available": undefined,
        "url": "https://www.gandi.net",
        "registerLink": "https://www.gandi.net/en/domain",
        "image": "https://dt2sdf0db8zob.cloudfront.net/wp-content/uploads/2019/08/gandi-logo.png",
        "category": "domains" 
    },
    {
        "name": "net",
        "available": undefined,
        "url": "https://www.gandi.net",
        "registerLink": "https://www.gandi.net/en/domain",
        "image": "https://dt2sdf0db8zob.cloudfront.net/wp-content/uploads/2019/08/gandi-logo.png",
        "category": "domains" 
    },
    {
        "name": "info",
        "available": undefined,
        "url": "https://www.gandi.net",
        "registerLink": "https://www.gandi.net/en/domain",
        "image": "https://dt2sdf0db8zob.cloudfront.net/wp-content/uploads/2019/08/gandi-logo.png",
        "category": "domains" 
    },
    {
        "name": "org",
        "available": undefined,
        "url": "https://www.gandi.net",
        "registerLink": "https://www.gandi.net/en/domain",
        "image": "https://dt2sdf0db8zob.cloudfront.net/wp-content/uploads/2019/08/gandi-logo.png",
        "category": "domains" 
    }
];