import './Footer.css';

export const Footer = () => (
      <div className="footer">
        <div className="footer-text">
            &copy; 2023 SOCIABLEBRAND a project by 
            <div className="footer-links">
              <a href="https://twitter.com/hendrik01001101">hendrik01001101</a>
              <a href="/privacy">Privacy</a>
              <a href="/tos">Terms of Service</a>
            </div>
        </div>
        <div className="footer-social">
          <div className="footer-social-link">
          </div>
        </div>
      </div>
    );
  
